<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="field.title"
      :rules="validationsRules"
    >
      <template v-if="currentValue">
        {{ currentValue | datetime(undefined, field.attributes.allowTime) }}
      </template>
      <template v-else> --- </template>
    </FlotoFormItem>
    <FlotoFormItem v-else :label="field.title" :rules="validationsRules">
      <div
        :class="{
          'w-full': field.attributes.widthClass !== 'w-full',
          'w-1/4': field.attributes.widthClass === 'w-full',
        }"
      >
        <FlotoDatePicker
          v-model="currentValue"
          :show-time="field.attributes.allowTime"
          v-bind="attrs"
          :name="field.title"
          :disabled="
            disabled ||
            mode === 'builder' ||
            field.attributes.systemManagedField ||
            isDisable
          "
          :read-only="readOnly"
          v-on="listeners"
        />
      </div>
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Datetime',
  mixins: [FormField],
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        nonzero: this.isRequired || false,
      }
      // apply form rule action for mandatory non-mandatory
      // if (this.isRuleDirty) {
      //   rules.required = this.isMandatory || false
      //   rules.nonzero = this.isMandatory || false
      // }
      // if field rule is disable then remove required validation
      if (this.isDisable) {
        rules.required = false
        rules.nonzero = false
      }
      return rules
    },
  },
}
</script>
