import SortBy from 'lodash/sortBy'
import api from '@api'
import GroupBy from 'lodash/groupBy'
import Moment from 'moment'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { transformCategory } from '@data/category'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function searchCategoriesApi(
  moduleName,
  { name, selectedName, isPublic, quals },
  limit,
  offset,
  params = {}
) {
  return api
    .post(
      `${isPublic ? '/public' : ''}/${moduleName}/category/search/byqual`,
      {
        ...(name || selectedName || quals
          ? {
              qualDetails: buildFlatQualificationStructure(
                [
                  name && buildNameFilterQuery(name),
                  selectedName && buildNameFilterQuery(selectedName),
                  ...(quals || []),
                ].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: SortBy(data.objectList || [], 'objOrder').map(transformCategory),
        total: data.totalCount,
      }
    })
}

export function createCategoriesApi(moduleName, data) {
  return api
    .post(`${moduleName}/category/bulk/create`, data, {
      message: __rootT('added_successfully', {
        resource: __rootTc('category', 2),
      }),
    })
    .then((data) => data.map(transformCategory))
}

export function createCategoryApi(moduleName, data) {
  const updateData = {
    ...data,
    categoryPrefix: (data.categoryPrefix || '').toUpperCase(),
    requesterGroupIds: data.requesterGroupIds || [],
    companyIds: data.companyIds || [],
  }
  return api
    .post(`${moduleName}/category`, updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('category'),
      }),
    })
    .then(transformCategory)
}

export function removeCategoryApi(moduleName, data) {
  return api.delete(`${moduleName}/category/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('category'),
    }),
  })
}

export function updateCategoryApi(moduleName, data) {
  const updateData = {
    name: data.name,
    disabled: data.disabled,
    description: data.description,
    categoryPrefix: (data.categoryPrefix || '').toUpperCase(),
    requesterGroupIds: data.requesterGroupIds || [],
    companyIds: data.companyIds || [],
  }
  return api
    .patch(`${moduleName}/category/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('category'),
      }),
    })
    .then(transformCategory)
}

export function updateCategoriesApi(moduleName, data) {
  const patchData = {}
  data.map((c) => {
    patchData[c.id] = { name: c.name, objOrder: c.order }
  })
  return api.patch(`${moduleName}/category/bulk/update`, patchData, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('category', 2),
    }),
  })
}

export function getCategoryApi(moduleName, id) {
  return api.get(`${moduleName}/category/${id}`).then(transformCategory)
}
export const transformKeyword = (keyword) => {
  return {
    keyword: keyword.keyword,
    name: keyword.displayName,
    group: keyword.groupName,
    customFieldType: keyword.fieldType,
    format: keyword.format,
  }
}

export function getCategoryKeywordsApi(flotoModel) {
  return api.get(`/${flotoModel}/static/keywords`).then((data) => {
    const d = data.keywords.map((d) => ({
      ...d,
      groupName: __rootTc('category'),
      format: Moment().format(`${d.displayName}`),
    }))
    return GroupBy(d.map(transformKeyword), 'group')
  })
}
