import { authComputed } from '@state/modules/auth'
import { useOnPortalEnabledModules } from '@data/form'
export default {
  props: {
    field: { type: Object, required: true },
    applyFormRules: { type: Boolean, default: false },
    fieldRulesState: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...authComputed,
    isHide() {
      if (this.field.markAsHidden) {
        return true
      }
      if (!this.applyFormRules) {
        return false
      }
      if (this.isPortalLogin) {
        if (
          useOnPortalEnabledModules.indexOf(this.field.moduleName) >= 0 &&
          this.field.requesterRequired
        ) {
          return false
        }
      } else if (this.field.required) {
        return false
      }
      const fieldKey = this.field.isSystemField
        ? this.field.paramName
        : this.field.id
      return (
        this.fieldRulesState[fieldKey] && this.fieldRulesState[fieldKey].hide
      )
    },
  },
}
