var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(_vm.disabled)?_c('FlotoFormItem',{attrs:{"label":_vm.label,"rules":_vm.validationsRules}},[(_vm.field.fieldResolutionType === 'system_field')?_c('CustomAsSystemPicker',{attrs:{"text-only":"","disabled":_vm.disabled,"as-input":false,"value":_vm.currentValue,"module-name":_vm.field.moduleName || _vm.moduleName,"placeholder":"---","field-reference-type":_vm.field.fieldReferenceType}}):_c('span',[_vm._v(" "+_vm._s(_vm.currentValue || '---')+" ")])],1):_c('FlotoFormItem',{attrs:{"label":_vm.label,"rules":_vm.validationsRules}},[_c(_vm.field.fieldResolutionType === 'system_field' &&
        _vm.field.fieldReferenceType
          ? 'CustomAsSystemPicker'
          : 'ExtendedDropdown',_vm._g(_vm._b({tag:"component",class:{
        block: true,
        'w-full': _vm.field.attributes.widthClass !== 'w-full',
        'w-1/2': _vm.field.attributes.widthClass === 'w-full',
      },attrs:{"module-name":_vm.field.moduleName || _vm.moduleName,"disabled":_vm.disabled ||
        _vm.mode === 'builder' ||
        _vm.field.attributes.systemManagedField ||
        _vm.isDisable,"searchable":"","read-only":_vm.readOnly,"hidden-options-keys":_vm.hiddenOptionsKeys,"visible-options-keys":_vm.visibleOptionsKeys,"mandatory-selection":_vm.field.fieldResolutionType === 'system_field',"as-input":"","field-reference-type":_vm.field.fieldReferenceType},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'component',Object.assign({}, _vm.attrs,
        {options:
          _vm.field.fieldResolutionType === 'user_defined'
            ? _vm.field.options.map(function (o) { return ({ key: o, value: o, text: o }); })
            : undefined}),false),_vm.listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }